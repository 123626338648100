    /**
     * @class SpinningButtonController
     */
    var SpinningButtonController = (function(m, require){

        var spinningButtonModelClass = require('apps/common/viewmodules/spinning-button/model-spinning-button');

        var init = function(args) {

            /**
             * @type {SpinningButtonModel}
             */
            this.spinningButtonModel = (function() {
                var model = null;
                if (args && args.spinningButtonModel) {
                    model = args.spinningButtonModel;
                } else {
                    model = new spinningButtonModelClass(args);
                }
                return model;
            })();
        };

        return init;
    })(m, require);

    module.exports = SpinningButtonController;