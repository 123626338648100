    /**
     * @class BaseService
     */
    var BaseService = (function(m){
        /**
         * @param {String} serviceUrl
         */
        var init = function(serviceUrl) {
            this.BASE_URL = '/editar/api';

            var defaultServiceUrl = serviceUrl;

            /**
             * @type {BaseService}
             */
            var self = this;

            /**
             * @param {String} method
             * @param {Object} data
             * @returns {Promise<any>}
             */
            var doRequest = function(method, data) {
                return m.request({
                    url: self.getServiceUrl(),
                    data: data,
                    method: method,
                    background: true,
                    /**
                     * @param {XMLHttpRequest} xhr
                     */
                    config: function (xhr) {
                        //xhr.setRequestHeader('Accepts', 'application/json');
                        //xhr.setRequestHeader('Content-Type', 'application/json');
                    },
                    unwrapError:function (a, b, c) {
                        console.log([a, b, b])
                    },
                    /**
                     * @param {Object} data
                     */
                    serialize:function (data) {
                        return JSON.stringify(data);
                    },
                    /**
                     * @param {String} data
                     */
                    deserialize:function(data) {
                        return JSON.parse(data);
                    },
                    /**
                     * @param {XMLHttpRequest} xhr
                     * @returns {String}
                     */
                    extract: function(xhr) {
                        try {
                            JSON.parse(xhr.responseText);
                            return xhr.responseText;
                        } catch (
                            /**
                             * @type Error
                             */
                            e
                            ) {
                            return JSON.stringify({
                                erro:e.message,
                                detalhes:e.stack,
                                status:xhr.status
                            });
                        }
                    }//,

                    //deserialize: jQuery.parseJSON
                })
            };

            /**
             * @param {String} pathVariable
             * @returns {BaseService}
             */
            this.setPathVariable = function(pathVariable) {
                if (pathVariable.match(/\/.*?/) !== null) {
                    serviceUrl = defaultServiceUrl + pathVariable;
                }
                return self;
            };

            /**
             * @param {String...} pathVariable
             * @returns {BaseService}
             */
            this.replacePath = function() {
                var url = defaultServiceUrl;
                var args = _.map(arguments);
                while (args.length > 0) {
                    url = url.replace('%s', args.shift());
                }
                serviceUrl = url;
                return self;
            };

            /**
             * @throws {Error}
             * @returns {string}
             */
            this.getServiceUrl = function() {
                if (serviceUrl === '') {
                    throw new Error('ServiceUrl está vazia!');
                }
                return self.BASE_URL + serviceUrl;
            };

            /**
             * @param {Object} data
             * @returns {Promise.<any>}
             */
            this.getData = function(data) {
                return doRequest('GET', data);
            };

            /**
             * @param {Object} data
             * @returns {Promise<any>}
             */
            this.postData = function(data) {
                return doRequest('POST', data);
            }
        };

        return init;
    })(m);

    module.exports = BaseService;
