    /**
     * @class SpinningButtonModel
     */
    var SpinningButtonModel = (function(m){
        var init = function(data) {
            data = data || {spin:false, onsend:function(){}, name:'Ok'};
            this.onsend = data.onsend;
            this.spin = data.spin;
            this.name = data.name;
        };

        return init;
    })(m);

    module.exports = SpinningButtonModel;