    /**
     * @class SpinningButtonView
     */
    var SpinningButtonView = (function(m){
        /**
         * @type {SpinningButtonModel}
         */
        var spinningButtonModel = require('apps/common/viewmodules/spinning-button/model-spinning-button');

        /**
         * @param {SpinningButtonController} ctrl
         * @param {SpinningButtonModel} args
         */
        var init = function(ctrl, args) {
            return m('button', {onclick: args.onsend.bind(this, new spinningButtonModel(args))}, [
                args.name + ((args.spin === true) ? '...' : ''),
                (args.spin === true) ? m('i.fa.fa-spin.fa-spinner') : m('')
            ]);
        };

        return init;
    })(m);

    module.exports = SpinningButtonView;