    /**
     * @class ApiSiteKeyService
     */
    var ApiSiteKeyService = (function(m, require){
        var init = function() {
            var SERVICE_URL = '/site-key';

            /**
             * @type {BaseService}
             */
            var baseService = new (require('apps/common/service/base-service'))(SERVICE_URL);

            /**
             * @returns {Promise<any>}
             */
            this.execute = function() {
                return baseService.getData(null);
            };
        };

        return init;
    })(m, require);

    module.exports = ApiSiteKeyService;